import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import alertSlice from './Components/common/popup/alertSlice';
import noticePopupSlice from './Components/common/popup/noticePopupSlice';
import popupSlice from './Components/common/popup/popupSlice';
import indicatorSlice from './Components/common/view/indicatorSlice';
import headerSlice from './Components/header/headerSlice';
import contactSlice from './Components/sections/contactSlice';
import noticeSlice from './Components/sections/noticeSlice';

const rootReducer = combineReducers({
    header: headerSlice,
    popup: popupSlice,
    alert: alertSlice,
    indicator: indicatorSlice,
    noticePopup: noticePopupSlice.reducer,
    notice: noticeSlice.reducer,
    contact: contactSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;