import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import styled from "styled-components";
import { useAppDispatch } from '../../store';
import Alert from '../common/popup/Alert';
import { showFour } from '../common/view/indicatorSlice';
import ProjectContact from "./ProjectContact";
import RecruitContact from "./RecruitContact";

const ContactWrap = styled.div`
  padding: 160px 60px 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  @media screen and (max-width:768px){
    padding: 120px 30px;
    padding-bottom: 50px;
  }
`;

const BoxContactTitle = styled.div`
  width: 222px;
  display: inline-block;
  margin-right: 68px;
  font-size: 42px;
  font-weight: 900;
  line-height: 61px;
  letter-spacing: -0.02em;
  text-align: left;
  white-space: nowrap;
  padding-bottom: 40px;
  span {
    color: #db1e26;
  }
`;

const BoxContactField = styled.div`
  max-width: 1016px;
  display: inline-block;
  vertical-align: top;
`;

const BoxTabContect = styled.ul`
  width: 100%;
  position: relative;
`;
const BoxTabItem = styled.li`
  position: relative;
  display: inline-block;
  &:not(:last-child) {
    margin-right: 40px;
  }
  a {
    display: block;
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #1f1f1f;
    padding: 0 0 2px 3px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #727272;
    }
  }
  &.active a {
    color: #db1e26;
  }
  &.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 13px;
    background: rgba(210, 30, 38, 0.1);
    position: absolute;
    bottom: 6px;
  }
`;

const SubText = styled.i`
  position: relative;
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #db1e26;
  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: -12PX;
    font-size: 20px;
  }
  
  @media screen and (max-width: 768px){
    top: 55px;

  }
`;

// 프로젝트의뢰 contact / 채용 recruit
export default function Contact() {

  let [categoryActive, setCategoryActive] = useState("프로젝트 의뢰");

  const dispatch = useAppDispatch();

  const [ref, inView] = useInView();

  useEffect(()=>{
    if(inView) {
      dispatch(showFour());
    }
  },[inView]);
 
  return (
    <ContactWrap ref={ref}>
      <Alert />
      <BoxContactTitle>
        꿈을 현실로 <br className="br_tag" />
        만드는 <br />
        <span>비알아이티</span>와<br className="br_tag" />
        함께 하세요.
      </BoxContactTitle>
      <BoxContactField>
        <BoxTabContect>
          <BoxTabItem onClick={() => {setCategoryActive("프로젝트 의뢰")}} className={categoryActive === "프로젝트 의뢰" ? "active" : null}>
            <a href="#!" title="프로젝트 의뢰">프로젝트 의뢰</a>
          </BoxTabItem>
          <BoxTabItem onClick={() => {setCategoryActive("입사지원")}} className={categoryActive === "입사지원" ? "active" : null}>
            <a href="#!" stitle="입사지원">입사지원</a>
          </BoxTabItem>
          <SubText>필수 입력 사항</SubText>
          {categoryActive === "프로젝트 의뢰" ? <ProjectContact /> : <RecruitContact />}
        </BoxTabContect>
      </BoxContactField>
    </ContactWrap>
  );
}
