import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from "styled-components";
import TextDecoImg from "../../assets/images/img_title_bg.svg";
import { useAppDispatch } from '../../store';
import { showOne } from '../common/view/indicatorSlice';
import { lightMode } from '../header/headerSlice';

const IntroWrap = styled.div`
  display: flex;
  padding-top: 80px;
  /* padding-bottom: 216px; */
  max-width: 1600px;
  /* background-color: #f78ae0; */
`;

const ContainMain = styled.div`
  padding-top: 80px;
  padding-left: 54px;
  padding-right: 54px;
  
  @media screen and (max-width: 768px) { 
    padding-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const BoxMainTitle = styled.div`
  padding: 186px 0 216px;
  position: relative;

  @media screen and (max-width: 768px) { 
    padding: 46.5px 0 70px; 
  }
`;

const TextSub = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  
  @media screen and (max-width: 768px) { 
    font-size: 20px;
    line-height: 17.5px;
  }
`;

const TextTitle = styled.div`
  margin-top: 58px;
  font-weight: 900;
  font-size: 120px;
  line-height: 144px;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  position: relative;
  z-index: 50;
  span {
    color: #db1e26;
  }

  @media screen and (max-width: 768px) { 
    font-size: 50px;
    line-height: 150%;
  }
`;

const BrTag = styled.br`
  &:not(:last-child) {
    display: none;
  }
`;

const TextDecoimg = styled.span`
  background-image: url("${TextDecoImg}");
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;
  background-position: center;
  background-size: 100%;
`;

const TitleAni = keyframes`
  0% {
    transform: translate(-6px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const TextDeco1 = styled.i`
  position: relative;
  letter-spacing: -0.02em;
  color: #000;
  &::before {
    content: 'CREATING';
    text-shadow: -1px 0px #000, 0px 1px #000, 1px 0px #000, 0px -1px #000;
    position: absolute;
    top: -10px;
    left: 6px;
    z-index: -100;
    color: #fff;
    animation: ${TitleAni} 3s forwards ease-in-out;
  }
`;

const TextDeco2 = styled.i`
  position: relative;
  letter-spacing: -0.02em;
  color: #db1e26;
  &::before {
    content: 'IT';
    text-shadow: -1px 0px #db1e26, 0px 1px #db1e26, 1px 0px #db1e26, 0px -1px #db1e26;
    position: absolute;
    top: -10px;
    left: 6px;
    z-index: -100;
    color: #fff;
    animation: ${TitleAni} 3s forwards ease-in-out;
  }
`;

export default function Intro() {

  const dispatch = useAppDispatch();

  const [ref, inView] = useInView();

  useEffect(()=>{
    if(inView) {
      dispatch(lightMode());
      dispatch(showOne());
    }
  },[inView]);


  return (
    <IntroWrap ref={ref}>
      <ContainMain>
        <BoxMainTitle>
          <TextSub>비알아이티는 더 좋은 생활을 만듭니다.</TextSub>
          <TextTitle>
            <TextDeco1> CREATING </TextDeco1><BrTag /> A <TextDecoimg>BETTER</TextDecoimg>
            <BrTag /> WORLD Through <TextDeco2> IT </TextDeco2> <span>&lt;&#47;BRIT&gt;</span>
          </TextTitle>
        </BoxMainTitle>
      </ContainMain>
    </IntroWrap>
  );
}
