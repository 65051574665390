import { createSlice } from '@reduxjs/toolkit';

const ScrollLock = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
}

const ScrollUnlock = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

const initialState = {
  privacyMode: false,
  collectMode: false,
  useMode: false,
  collectAgree: false,
  useAgree: false,
  collectContent: '',
  useContent: '',
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPrivacy: (state) => {
      state.privacyMode = true;
      ScrollLock();
    },
    showCollect: (state) => {
      state.collectMode = true;
      ScrollLock();
    },
    showUse: (state) => {
      state.useMode = true;
      ScrollLock();
    },
    popupHide: (state) => {
      state.privacyMode = false;
      state.collectMode = false;
      state.useMode = false;
      ScrollUnlock();
    },
    agreeCollect: (state, { payload }) => {
      state.collectMode = false;
      state.collectAgree = true;
      state.collectContent = payload;
      ScrollUnlock();
    },
    disagreeCollect: (state) => {
      state.collectMode = false;
      state.collectAgree = false;
      state.collectContent = '';
      ScrollUnlock();
    },
    agreeUse: (state, { payload }) => {
      state.useMode = false;
      state.useAgree = true;
      state.useContent = payload;
      ScrollUnlock();
    },
    disagreeUse: (state) => {
      state.useMode = false;
      state.useAgree = false;
      state.useContent = '';
      ScrollUnlock();
    },
  },
});

export const { showPrivacy, showCollect, showUse, popupHide, agreeCollect, disagreeCollect, agreeUse, disagreeUse } =
  popupSlice.actions;

export default popupSlice.reducer;
