import React from 'react';
import { useSelector } from "react-redux";
import '../../../assets/style/alert.css';
import { useAppDispatch } from '../../../store';
import { closeAlert } from './alertSlice';

const Alert = () => {

    const alert = useSelector((state) => state.alert);
    const dispatch = useAppDispatch();
  
    const onClickAlertClose=()=>{
      dispatch(closeAlert());
    }
    
    return (
        <div className={alert.mode ? "contain-alert active" : "contain-alert"}>
          <div className="contain-inner">
            <div className="box-alert-body">
              <span className="icon-info" />
              <p className="text-msg">{alert.alertTitle}</p>
            </div>
            <div className="box-alert-btn">
              <button type="button" className="btn-closelayer" onClick={onClickAlertClose}>확인</button>
            </div>
          </div>
        </div>
    );
};

export default Alert;