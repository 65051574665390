import React from "react";
import About from "./About";
import Contact from "./Contact";
import Intro from "./Intro";
import Notice from "./Notice";
import Technology from "./Technology";

import styled from "styled-components";

const SectionWrap = styled.div`
  position: flex;
  max-width: 1600px;
  margin: 0 auto;
`;

const SectionId = styled.div`
  padding-top: 80px;
`;

export default function Section() {
  return (
    <SectionWrap>
      <div>
        <Intro />
      </div>
      <SectionId id="1">
        <Notice />
      </SectionId>
      <div id="2">
        <Technology />
      </div>
      <div id="3">
        <About />
      </div>
      <div id="4">
        <Contact />
      </div>
    </SectionWrap>
  );
}
