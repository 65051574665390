import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/fonts/font.css";
import App from "./App";
import "./index.css";
import store from './store';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
