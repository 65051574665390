import React, { useEffect, useState } from "react";
import Collapsible from 'react-collapsible';
import { useInView } from 'react-intersection-observer';
import styled from "styled-components";
import Emoji from "../../assets/images/ico_active_emoji.svg";
import ArrowDownImg from "../../assets/images/ico_arrow_down.svg";
import ArrowBtn from "../../assets/images/ico_btn_all.svg";
import { useAppDispatch, useAppSelector } from '../../store';
import NoticePopup from '../common/popup/NoticePopup';
import { noticePopupShow } from '../common/popup/noticePopupSlice';
import { showOne } from '../common/view/indicatorSlice';
import { getNotice } from './noticeSlice';

const NewsWrap = styled.div`
  /* display: flex; */
  padding-top: 81px;
  padding-bottom: 134px;
  padding-left: 54px;
  padding-right: 54px;
  height: auto;
  max-width: 1600px;
  /* background-color: #6638f0; */

  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    padding : 20px 7.5% 67px 7.5% ;
  }
`;

const TextNewsTitle = styled.strong`
  font-weight: 900;
  font-size: 60px;
  line-height: 87px;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  white-space: nowrap;
  /* display: block; */

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    line-height: 43.5px;
  }
`;

const BoxNewsMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
`;

const ItemMenu = styled.li`
  display: flex;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 24px;
  }
  &.active {
    a {
      display: block;
      color: #db1e26;
      white-space: nowrap;
      text-decoration: none;
      &:before {
        content: "";
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url("${Emoji}") no-repeat center;
        background-size: contain;
        margin-right: 4px;
        vertical-align: middle;
      }
    }
  }
  a {
    font-size: 20px;
    font-weight: 900;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #bbbbbb;
    text-decoration: none;
    &:hover {
      color: #1f1f1f;
    }
  }
`;

const BoxNewsList = styled.ul`
  margin-top: 40px;
`;

const BoxNewsItem = styled.li`
  display: flex;
  align-items: top;
  &:not(:first-child) {
    margin-top: 36px;
  }

  @media (max-width: 768px){
    flex-wrap: wrap;
  }
`;

const TextItemCategory = styled.span`
  font-size: 20px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #db1e26;
  max-width: 50px;
  margin-right: 70px;

  @media (max-width: 768px){
    max-width: none;
    margin-right:auto;
    width: 100%;
  }
`;

const BoxNewsContents = styled.div`
  width: calc(100% - 130px);
  @media (max-width: 768px){
    width: 100%;
  }
`;

const BoxConTop = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &::after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("${ArrowDownImg}") no-repeat center;
    background-size: contain;
    vertical-align: middle;
  }
  
  @media (max-width:768px){
    position: relative;
    &::after{
      position: absolute;
      top: 5px; right: -1px;     
    }
  }
`;

const TextTopTitle = styled.span`
  /* display: block; */
  font-weight: 600;
  font-size: 24px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  width: calc(100% - 242px);
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px){
    font-size: 20px;
    width: calc(100% - 40px);
    line-height: 200%;
  }
`;

const TextTopDate = styled.span`
  /* display: flex; */
  font-size: 20px;
  line-height: 29px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #8a8a8a;
  margin-right: 105px;
  @media (max-width: 768px){
    font-size: 15px;
    line-height: 30px;
    margin-right: 28px;
  }
`;

const BoxConBody = styled.div`
  position: relative;
  background: #f3f3f3;
  margin-top: 25px;
  /* display: none; */
  img {
    display: block;
    width: 176px;
    height: 157px;
  }
`;

const BoxConView = styled.div`
  height: 368px;
  overflow-y: auto;
  padding: 56px 56px 0 56px;
  
  @media (max-width:768px){
    padding: 20px 15px; 
  }
`;

const BoxConDetail = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #4a4a4a;
`;

const BoxConBtn = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 28px 56px 32px;
  background: #f3f3f3;
  cursor: pointer; 
  a {
    display: block;
    font-family: "Pretendard";
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #1f1f1f;
    text-align: right;
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("${ArrowBtn}") no-repeat center;
      background-size: contain;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  @media (max-width:768px){
    padding: 15px 20px;
  }
`;

export default function News() {

  const dispatch = useAppDispatch();

  const [ref, inView] = useInView();

  const notices = useAppSelector(state => state.notice.list);

  const [selectedId, setSelectedId] = useState(null);
  const [count, setCount] = useState(0);
  const [seq, setSeq] = useState('');
  
  useEffect(()=>{
    if(inView) {
      dispatch(showOne());
    }
  },[inView]);

  useEffect(() => {
    setSelectedId(null);
    setCount(1);
  }, []);

  useEffect(() => {
    dispatch(getNotice(selectedId));
  }, [selectedId]);

  const onClickAll = () => {
    setSelectedId(null)
  }
  
  const onClickNotice = () => {
    setSelectedId("C1001")
  }

  const onClickStory = () => {
    setSelectedId("C1004")
  }

  const onClickConBtn = (e) => {
    setSeq(e.target.type)
    dispatch(noticePopupShow())
  }

  return (
    <NewsWrap ref={ref} >
      <TextNewsTitle>&lt;&#47;What’s New?&gt;</TextNewsTitle>
      <BoxNewsMenu>
        <ItemMenu
          className={selectedId === null ? "active" : null}
          onClick={onClickAll}
        >
          <a href="#!" title="ALL">ALL</a>
        </ItemMenu>
        <ItemMenu
          value="C1001"
          className={selectedId === "C1001" ? "active" : null}
          onClick={onClickNotice}
        >
          <a href="#!" title="Notice">Notice</a>
        </ItemMenu>
        <ItemMenu
          value="C1004"
          className={selectedId === "C1004" ? "active" : null}
          onClick={onClickStory}
        >
          <a href="#!" title="Story">Story</a>
        </ItemMenu>
      </BoxNewsMenu>

      <BoxNewsList>
        {notices &&
          notices.map((notice, i) => (
            <BoxNewsItem key={notice.noticeSeq}>
              <TextItemCategory>{notice.noticeGbCdNm}</TextItemCategory>
              <BoxNewsContents>
                <Collapsible
                  open={count === i+1}
                  handleTriggerClick={() => setCount(i+1)}
                  trigger={
                    <BoxConTop>
                      <TextTopTitle>{notice.title}</TextTopTitle>
                      <TextTopDate>{notice.createDate}</TextTopDate>
                    </BoxConTop>
                  }
                >
                  <BoxConBody>
                    <BoxConView>
                      <BoxConDetail dangerouslySetInnerHTML={{ __html: notice.contents }} />
                    </BoxConView>
                    <BoxConBtn>
                      <a type={notice.noticeSeq} onClick={onClickConBtn} href="#!" title="전체보기">전체보기</a>
                    </BoxConBtn>
                  </BoxConBody>
                </Collapsible>
              </BoxNewsContents>
            </BoxNewsItem>
          ))}
          
        <NoticePopup seq={seq}/>
      </BoxNewsList>
    </NewsWrap>
  );
}
