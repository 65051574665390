import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";
import CheckBoxType1 from "../../assets/images/btn_checkbox_type1.svg";
import CheckBoxType2 from "../../assets/images/btn_checkbox_type2.svg";
import ArrowSelectImg from "../../assets/images/ico_arrow_select.svg";
import { useAppDispatch } from '../../store';
import { openAlert } from '../common/popup/alertSlice';
import { showCollect, showUse } from '../common/popup/popupSlice';
import ContactButton from "../common/view/ContactButton";
import { postProject } from './contactSlice';

const BoxUserForm = styled.div`
  margin-top: 23px;
  width: 100%;
`;

const BoxProjectForm = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const TextFormTitle = styled.p`
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: -0.02em;
  margin-top: 13px;
  color: #000;
  position: relative;
`;

const TextEssential = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #db1e26;
  position: absolute;
  top: 5px;
  left: -15px;
`;

const BoxTypeCon = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;
`;

const TextBoxTitle = styled.p`
  margin-top: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000;

  @media (max-width:768px){
    font-size: 15px;
  }
`;

const TextBoxSub = styled.span`
  margin-top: 12px;
  display: block;
  line-height: 27px;
  font-size: 18px;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #000;
    margin-right: 6px;
    vertical-align: middle;
  }

  @media (max-width:768px){
    font-size: 15px;
  }
`;

const WritingContentForm = styled.div`
  margin-top: 32px;
  width: 100%;
  textarea {
    margin-top: 13px;
    display: block;
    width: calc(100% - 48px);
    height: 184px;
    overflow-y: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #404040;
    padding: 18px 24px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    outline: none;
    resize: none;
    :focus-visible {
      border: 1px solid #404040;
    }
    ::placeholder {
      color: #bbbbbb;
    }

    @media screen and (max-width: 768px){
      ::placeholder{
        font-size: 14px;
      }
    }

    /* &:focus-visible {
      border: 1px solid #404040;
    } */
  }
`;

const BoxInputFrom = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin-top: 20px;
  justify-content: space-between;
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
  & > div {
    display: block;
    width: calc(50% - 12px);

    @media screen and (max-width: 425px){
      width: 100%
    }
  }
`;

const StyledSelect = styled.div`
  position: relative;
  select {
    width: 100%;
    display: block;
    padding: 19px 20px 19px 100px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    outline: none;
    resize: none;
    line-height: 24px;
    background: url("${ArrowSelectImg}") no-repeat 95% 50%;
    appearance: none;
    font-size: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #3f3f3f;
    font-family: "Pretendard";
    &:focus {
      border: 1px solid #404040;
    };
    option {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.02em;
      line-height: 64px;
      
    }
    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  };
  label {
    position: absolute;
    left: 24px;
    top: 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #3f3f3f;
    line-height: 64px;
    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  };
`;

const StyledInput = styled.div`
  display: block;
  position: relative;
  label {
    position: absolute;
    left: 24px;
    top: 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #3f3f3f;
    line-height: 64px;
    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  }
  input:focus {
    border: 1px solid #404040;
  }
  input {
    width: calc(100% - 120px);
    display: block;
    padding: 19px 20px 19px 100px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    outline: none;
    resize: none;
    line-height: 24px;
    font-family: "Pretendard";
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.02em;
      line-height: 64px;
      text-align: left;
      color: #bbb;
      font-family: "Pretendard";

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
`;

const InputEssential = styled.i`
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #db1e26;
  position: absolute;
  top: 20px;
  left: 9px;
`;

const BoxFileBtn = styled.div`
  margin-top: 16px;
  label {
    display: inline-block;

    @media screen and (max-width: 768px){
      display : block;
    }
  }
  input {
    display: none;
  }
  & + textarea {
    margin-top: 20px;
  }
`;

const BtnAddFile = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #fff;
  background: #1f1f1f;
  padding: 11px 23px 9px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  @media screen and (max-width:425px){
    box-sizing: border-box;
    text-align: center;
    width:100%
  }
`;

const TextFileInfo = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #bbbbbb;
  margin-left: 12px;
  .off {
    display:none;
  }

  @media screen and (max-width: 768px){
    margin-left: 0;
    font-size: 14px;
  }
`;

const BoxAgree = styled.ul`
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 425px){
    flex-wrap: wrap;
  }
`;

const BoxAgreeItem = styled.li`
  position: relative;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 40px;
  }
  input {
    appearance: none;
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("${CheckBoxType1}") no-repeat center;
    background-size: contain;
    vertical-align: middle;
    margin-right: 8px;
    &:checked {
      background: url("${CheckBoxType2}") no-repeat center;
    }
  }
  label {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
    color: #2b2b2b;
    text-decoration: underline;
    span {
      color: #db1e26;
      text-decoration: underline;
    }
  }
  .popup_open_btn {
    position: absolute;
    top: 2px;
    right: -30px;
    width: 24px;
    height: 24px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  @media screen and (max-width: 425px){
    padding: 10px 0;
    label{
      font-size: 14px;
    }   
  }
`;

const BoxSubmitBtn = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const BtnSubmit = styled.button`
  display: block;
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #db1e26;
  padding: 0;
  cursor: pointer;
  :hover {
    color: #8b0006;
  }
  :hover:after {
    background: #8b0006;
  }
  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 7px;
    background: #db1e26;
    margin-top: 8px;
  }
`;


export default function ProjectContact() {

  const dispatch = useAppDispatch();

  const popup = useSelector((state) => state.popup);

  const [field, setField] = useState({
    isSelected: 'C1701',
    textVal: '',
    scheduleVal: 'C1800',
    companyVal: '',
    nameVal: '',
    numberVal: '',
    emailVal:'',
    fileVal: null,
    agrUse: '',
    agrCollect: ''
  });

  const [phoneNum, setPhoneNum] = useState('');

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  
  useEffect(() => {
    setField({...field, agrUse: popup.useContent, agrCollect: popup.collectContent})
  }, [popup]);

  const radioButtonChangeHandler = (e) => {
    setField({...field, isSelected: e.currentTarget.value});
  };

  const onChangeText = (e) => {
    setField({...field, textVal: e.target.value});
  }

  const onChangeSchedule = (e) => {
    setField({...field, scheduleVal: e.target.value});
  }

  const onChangeCompany = (e) => {
    setField({...field, companyVal: e.target.value});
  }

  const onChangeName = (e) => {
    setField({...field, nameVal: e.target.value});
  }

  const onChangeNumber = (e) => {

    let val = e.target.value;
    val = val.substr(0,13);
    
    setPhoneNum(val.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, "$1-$2-$3").replace(/(\-{1,2})$/g, ""));
    setField({...field, numberVal: val.replaceAll('-', '')});
    
  }

  const onChangeEmail = (e) => {
    setField({...field, emailVal: e.target.value});
  }

  const fileUpload = (file) => {
    let fileName = file.target.files[0].name;
    let fileSize = file.target.files[0].size;
    let nameDiv = file.target.nextSibling;

    const maxSize = 20 * 1024 * 1024;

    if (file.target.value) {
      if (fileSize > maxSize) {

        file.target.value = '';

      }
      if (
        !fileName.endsWith('doc') &&
        !fileName.endsWith('docx') &&
        !fileName.endsWith('xlsx') &&
        !fileName.endsWith('xls') &&
        !fileName.endsWith('ppt') &&
        !fileName.endsWith('pptx') &&
        !fileName.endsWith('pdf') &&
        !fileName.endsWith('png') &&
        !fileName.endsWith('jpg') &&
        !fileName.endsWith('jpeg') &&
        !fileName.endsWith('zip')
      ) {
        dispatch(
          openAlert('업로드 가능한 파일 확장자는 \ndoc, docx, xlsx, xls, ppt, pptx, pdf,\npng, jpg, jpeg, zip 입니다')
        );
      } else {
        const infoMsg = file.target.closest('.file_item').querySelector('.file_info');
        infoMsg.classList.add('off');
        nameDiv.innerText = fileName;

        setField({ ...field, fileVal: file.target.files[0] });
      }
    }
    
  };
  
  let formData = {
    prjCateCd: field.isSelected,
    inqCont: field.textVal,
    prjOpenSchlCd: field.scheduleVal,
    companyNm: field.companyVal,
    inqerNm: field.nameVal,
    inqerPhoneNo: field.numberVal,
    inqerEmailAddr: field.emailVal,
    file: field.fileVal,
    prsInfo3cProAgrCont: field.agrUse,
    prsInfoCltAgrCont: field.agrCollect
  };

  function projectData() {
    setDisabledSubmit(true);
    return new Promise(resolve => {
        let data = dispatch(postProject(formData));
        resolve(data);
    });
  }

  const onSubmitProject = () => {
    const {
        textVal,
        scheduleVal,
        companyVal,
        nameVal,
        numberVal,
        emailVal,
        fileVal,
        agrUse,
        agrCollect,
    } = field;

    const regExpNumber =
        /^(\d{0,3})(\d{0,4})(\d{0,4})$/g;
    
    const regExpEmail =
        /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

    let nemberTest = regExpNumber.test(numberVal);
    let emailTest = regExpEmail.test(emailVal);

    if (
      textVal === '' ||
      scheduleVal === 'C1800' ||
      companyVal === '' ||
      nameVal === '' ||
      numberVal === '' ||
      nemberTest === false ||
      emailVal === '' ||
      emailTest === false ||
      fileVal === '' ||
      agrUse === '' ||
      agrCollect === ''
    ) {
      if (textVal === '') {
        dispatch(openAlert('문의 내용을 입력해주세요.'));
        document.getElementById('projectText').focus();
      } else if (scheduleVal === 'C1800') {
        dispatch(openAlert('예상 일정을 선택해주세요.'));
        document.getElementById('1').focus();
      } else if (companyVal === '') {
        dispatch(openAlert('회사명을 입력해주세요.'));
        document.getElementById('1').focus();
      } else if (nameVal === '') {
        dispatch(openAlert('담당자명을 입력해주세요.'));
        document.getElementById('3').focus();
      } else if (numberVal === '') {
        dispatch(openAlert('연락처를 입력해주세요.'));
        document.getElementById('4').focus();
      } else if (nemberTest === false) {
        dispatch(openAlert('연락처 형식이 올바르지 않습니다. 확인 후 다시 입력해주세요.'));
        document.getElementById('4').focus();
      } else if (emailVal === '') {
        dispatch(openAlert('이메일을 입력해주세요.'));
        document.getElementById('5').focus();
      } else if (emailTest === false) {
        dispatch(openAlert('이메일 형식이 올바르지 않습니다. 확인 후 다시 입력해주세요.'));
        document.getElementById('5').focus();
      } else if (agrUse === '') {
        dispatch(openAlert('개인정보 수집∙이용 동의를 확인해주세요.'));
        document.getElementById('check1').focus();
      } else if (agrCollect === '') {
        dispatch(openAlert('개인정보 취급위탁에 대한 동의를 확인해주세요.'));
        document.getElementById('check2').focus();
      }
    }
    else {
      projectData().then(resolvedData => {
        setDisabledSubmit(false);
        let result = resolvedData.type.endsWith('fulfilled');
        
        if (result) {
          setField({
              isSelected: "C1701",
              textVal: '',
              scheduleVal: 'C1800',
              companyVal: '',
              nameVal: '',
              numberVal: '',
              emailVal:'',
              fileVal: null,
              agrUse: '',
              agrCollect: ''
            });
            alert('프로젝트 의뢰가 접수되었습니다.\n담당자가 확인 후 연락드리겠습니다.');
            window.location.replace('/');
        } else {
            alert('오류가 발생하여 정상적으로 의뢰되지 않았습니다.');
        }
      });
    }
  };

  const onClickUsepopup = () => {
    dispatch(showUse());
  };

  const onClickcollectpopup = () => {
    dispatch(showCollect());
  };

  return (
    <BoxUserForm>
      <BoxProjectForm>
        <TextFormTitle>
          <TextEssential>*</TextEssential>
          1. 문의유형 1가지를 선택해주세요.
        </TextFormTitle>
        <ContactButton
          changed={radioButtonChangeHandler}
          id="1"
          isSelected={field.isSelected === "C1701"}
          label="Business"
          value="C1701"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="2"
          isSelected={field.isSelected === "C1702"}
          label="Web Service"
          value="C1702"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="3"
          isSelected={field.isSelected === "C1703"}
          label="APP Service"
          value="C1703"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="4"
          isSelected={field.isSelected === "C1704"}
          label="VR, AR, 메타버스"
          value="C1704"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="5"
          isSelected={field.isSelected === "C1705"}
          label="VR 관리 솔루션"
          value="C1705"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="6"
          isSelected={field.isSelected === "C1706"}
          label="스트리밍 플랫폼"
          value="C1706"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="7"
          isSelected={field.isSelected === "C1707"}
          label="기타"
          value="C1707"
        />
        <BoxTypeCon>
          <TextBoxTitle>
            의뢰 내용을 자세히 입력해주시면 빠른 확인이 가능합니다.
          </TextBoxTitle>
          <TextBoxSub>UI/UX 트렌드와 Mobile Device특성에 대한 이해</TextBoxSub>
          <TextBoxSub>
            기획업무에 필요한 설득력 있는 Paper Work(화면설계서 등 기획업무에
            필요한 각 문서)
          </TextBoxSub>
        </BoxTypeCon>
      </BoxProjectForm>
      <WritingContentForm>
        <TextFormTitle>
          <TextEssential>*</TextEssential>
          2. 내용을 자세히 적어주시면 빠른 답변에 도움이 됩니다.
        </TextFormTitle>
        <textarea
          name=""
          id="projectText"
          cols="30"
          rows="10"
          spellCheck="false"
          placeholder="선호하는 레퍼런스, 프로젝트 목적, 웹사이트 방향성, 문의 내용 등을 자세히 알려주세요. 
          예시) 
          프로젝트 제목 : BRIT 웹사이트 리뉴얼
          참고사이트 URL : br-it.co.kr 
          문의 내용 : 리뉴얼 관련하여 참고 URL를 첨부하오니 확인 부탁드립니다."
          onChange={onChangeText}
        ></textarea>
      </WritingContentForm>
      <BoxInputFrom>
        <StyledSelect>
          <InputEssential>*</InputEssential>
          <label>예상 일정</label>
          <select name="" id="1" type="text" required onChange={onChangeSchedule}>
            <option value="C1800 " defaultValue>
              계획한 일정을 알려주세요.
            </option>
            <option value="C1801">3개월 이내</option>
            <option value="C1802">3개월 ~ 6개월</option>
            <option value="C1803">6개월 이상</option>
            <option value="C1804">기타 (협의필요)</option>
          </select>
        </StyledSelect>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>회사명</label>
          <input id="2" type="text" placeholder="회사 또는 소속을 입력해주세요." onChange={onChangeCompany} />
        </StyledInput>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>담당자명</label>
          <input id="3" type="text" placeholder="담당자 및 본인 성함을 입력해주세요." onChange={onChangeName} />
        </StyledInput>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>연락처</label>
          <input id="4" type="text" value={phoneNum} placeholder="연락 가능한 연락처를 입력해주세요." onChange={onChangeNumber} />
        </StyledInput>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>이메일</label>
          <input id="5" type="text" placeholder="이메일 주소를 입력해주세요." onChange={onChangeEmail} />
        </StyledInput>
      </BoxInputFrom>
      <BoxFileBtn className="file_item">
        <label htmlFor="file">
          <BtnAddFile>파일 업로드</BtnAddFile>
        </label>
        <input type="file" name="file" id="file" onChange={fileUpload} /> 
        {
          (window.innerWidth < 769) ? <div style={{margin: `20px 0`}}></div> : null
        }
        <TextFileInfo className="file_info">
          (선택) RFP 및 참고문서 최대 20MB 이하 / 2개이상 파일은 압축하여
          zip파일로 올려주세요.
        </TextFileInfo>
      </BoxFileBtn>
      <BoxAgree>
        <BoxAgreeItem>
          <input id="check1" type="checkbox" name="agree" onClick={onClickUsepopup} checked={popup.useAgree} required/>
          <label htmlFor="check1" style={{'cursor': 'pointer'}}>
            개인정보 수집・이용 동의<span>(필수)</span>
          </label>
        </BoxAgreeItem>
        <BoxAgreeItem>
          <input id="check2" type="checkbox" name="agree" onClick={onClickcollectpopup} checked={popup.collectAgree} required/>
          <label htmlFor="check2" style={{'cursor': 'pointer'}}>
            개인정보 취급위탁에 대한 동의<span>(필수)</span>
          </label>
        </BoxAgreeItem>
      </BoxAgree>
      <BoxSubmitBtn>
        <BtnSubmit disabled={disabledSubmit} onClick={onSubmitProject}>&lt;&#47;Send&gt;</BtnSubmit>
      </BoxSubmitBtn>
    </BoxUserForm>
  );
}
