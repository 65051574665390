import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";
import CheckBoxType1 from "../../assets/images/btn_checkbox_type1.svg";
import CheckBoxType2 from "../../assets/images/btn_checkbox_type2.svg";
import ArrowNext1 from "../../assets/images/ico_arrow_next1.svg";
import ArrowNext2 from "../../assets/images/ico_arrow_next2.svg";
import { useAppDispatch } from '../../store';
import { openAlert } from '../common/popup/alertSlice';
import { showCollect, showUse } from '../common/popup/popupSlice';
import ContactButton from "../common/view/ContactButton";
import { postRecruit } from './contactSlice';

const BoxUserForm = styled.div`
  margin-top: 23px;
  width: 100%;
`;

const BoxProjectForm = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const TextFormTitle = styled.p`
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: -0.02em;
  margin-top: 13px;
  color: #000;
  position: relative;
`;

const TextEssential = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #db1e26;
  position: absolute;
  top: 5px;
  left: -15px;
`;

const BoxTypeCon = styled.div`
  /* background: #f5f5f5; */
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;
`;

const TextBoxTitle = styled.p`
  margin-top: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000;

  @media (max-width:768px){
    font-size: 15px;
  }
  `;
  
  const TextBoxSub = styled.span`
  margin-top: 12px;
  display: block;
  line-height: 27px;
  font-size: 18px;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #000;
    margin-right: 6px;
    vertical-align: middle;
  }
  @media (max-width:768px){
    font-size: 15px;
  }
  `;
  
  const WritingContentForm = styled.div`
  margin-top: 32px;
  width: 100%;
  textarea {
    margin-top: 13px;
    display: block;
    width: calc(100% - 48px);
    height: 184px;
    overflow-y: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #404040;
    padding: 18px 24px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    outline: none;
    resize: none;
    :focus-visible {
      border: 1px solid #404040;
    };
    ::placeholder {
      color: #bbbbbb;
      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    };

    
    /* &:focus-visible {
      border: 1px solid #404040;
    } */
  }
`;

const BoxInputFrom = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin-top: 20px;
  justify-content: space-between;
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
  & > div {
    display: block;
    width: calc(50% - 12px);
    @media screen and (max-width: 425px){
      width: 100%
    }
  }
`;

const StyledInput = styled.div`
  display: block;
  position: relative;
  label {
    position: absolute;
    left: 24px;
    top: 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #3f3f3f;
    line-height: 64px;

    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  }
  input:focus {
    border: 1px solid #404040;
  }
  input {
    width: calc(100% - 120px);
    display: block;
    padding: 19px 20px 19px 100px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    outline: none;
    resize: none;
    line-height: 24px;
    font-family: "Pretendard";
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.02em;
      line-height: 64px;
      text-align: left;
      color: #bbb;
      font-family: "Pretendard";

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
`;

const InputEssential = styled.i`
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #db1e26;
  position: absolute;
  top: 20px;
  left: 9px;
`;

const BoxFileBtn = styled.div`
  margin-top: 16px;
  position: relative;
  label {
    display: inline-block;
    width:100%
  }
  input {
    display: none;
  }
  & + textarea {
    margin-top: 20px;
  }
`;

const BtnAddFile = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #fff;
  background: #1f1f1f;
  padding: 11px 23px 9px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  
  @media screen and (max-width:425px){
    box-sizing: border-box;
    text-align: center;
    width:100%
  }

`;

const TextFileInfo = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #bbbbbb;
  margin-left: 12px;
  
  @media (max-width: 768px){
    margin-left: 0px;
    font-size: 14px;
  }
`;

const BoxAgree = styled.ul`
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 425px){
    flex-wrap: wrap;
  }
`;

const BoxAgreeItem = styled.li`
  position: relative;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 40px;
  }
  input {
    appearance: none;
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("${CheckBoxType1}") no-repeat center;
    background-size: contain;
    vertical-align: middle;
    margin-right: 8px;
    &:checked {
      background: url("${CheckBoxType2}") no-repeat center;
    }
  }
  label {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
    color: #2b2b2b;
    text-decoration: underline;
    span {
      color: #db1e26;
      text-decoration: underline;
    }
  }
  .popup_open_btn {
    position: absolute;
    top: 2px;
    right: -30px;
    width: 24px;
    height: 24px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  @media screen and (max-width: 425px){
    padding: 10px 0;
  }
`;

const BoxSubmitBtn = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const BtnSubmit = styled.button`
  display: block;
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #db1e26;
  padding: 0;
  cursor: pointer;
  :hover {
    color: #8b0006;
  }
  :hover:after {
    background: #8b0006;
  }
  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 7px;
    background: #db1e26;
    margin-top: 8px;
  }
`;

const BtnTextJoin = styled.a`
display: inline-block;
font-size: 20px;
font-weight: 700;
line-height: 29px;
letter-spacing: -0.02em;
color: #db1e26;
float: right;
margin-top: 13px;
cursor: pointer;
::after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("${ArrowNext1}") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  margin-left: 4px;
};
:hover {
  color: #1f1f1f;
};
:hover::after {
  background: url("${ArrowNext2}") no-repeat center;
};
`;

export default function RecruitContact() {

  const dispatch = useAppDispatch();

  const popup = useSelector((state) => state.popup);
  
  const [field, setField] = useState({
    isSelected: 'C1901',
    nameVal: '',
    numberVal: '',
    emailVal: '',
    urlVal: '',
    fileVal: null,
    textVal: '',
    agrUse: '',
    agrCollect: ''
  })

  const [phoneNum, setPhoneNum] = useState('');

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  
  useEffect(() => {
    setField({...field, agrUse: popup.useContent, agrCollect: popup.collectContent})
  }, [popup]);

  const radioButtonChangeHandler = (e) => {
    setField({...field, isSelected: e.currentTarget.value});
  };

  const onChangeName = (e) => {
    setField({...field, nameVal: e.target.value});
  }

  const onChangeNumber = (e) => {
    let val = e.target.value;
    val = val.substr(0,13);
    
    setPhoneNum(val.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, "$1-$2-$3").replace(/(\-{1,2})$/g, ""));
    setField({...field, numberVal: val.replaceAll('-', '')});
  }
  
  const onChangeEmail = (e) => {
    setField({...field, emailVal: e.target.value});
  }

  const onChangeUrl = (e) => {
    setField({...field, urlVal: e.target.value});
  }

  const fileUpload = (file) => {
    let fileName = file.target.files[0].name;
    let fileSize = file.target.files[0].size;
    let nameDiv = file.target.nextSibling;

    const maxSize = 20 * 1024 * 1024;

    if (file.target.value) {
      if (fileSize > maxSize) {

        file.target.value = '';

      }
      if (
        !fileName.endsWith('doc') &&
        !fileName.endsWith('docx') &&
        !fileName.endsWith('xlsx') &&
        !fileName.endsWith('xls') &&
        !fileName.endsWith('ppt') &&
        !fileName.endsWith('pptx') &&
        !fileName.endsWith('pdf') &&
        !fileName.endsWith('png') &&
        !fileName.endsWith('jpg') &&
        !fileName.endsWith('jpeg') &&
        !fileName.endsWith('zip')
      ) {
        dispatch(
          openAlert('업로드 가능한 파일 확장자는 \ndoc, docx, xlsx, xls, ppt, pptx, pdf,\npng, jpg, jpeg, zip 입니다')
        );
      } else {
        const infoMsg = file.target.closest('.file_item').querySelector('.file_info');
        infoMsg.classList.add('off');
        nameDiv.innerText = fileName;

        setField({ ...field, fileVal: file.target.files[0] });
      }
    }
    
  };

  const onChangeText = (e) => {
    setField({...field, textVal: e.target.value});
  }
  
  let formData = {
    joinSupFidCd: field.isSelected,
    inqerNm: field.nameVal,
    inqerPhoneNo: field.numberVal,
    inqerEmailAddr: field.emailVal,
    ptfUrlAddr: field.urlVal,
    file: field.fileVal,
    inqCont: field.textVal,
    prsInfo3cProAgrCont: field.agrUse,
    prsInfoCltAgrCont: field.agrCollect
  };

  function recruitData() {
    setDisabledSubmit(true);
    return new Promise(resolve => {
        let data = dispatch(postRecruit(formData));
        resolve(data);
    });
  }

  const onSubmitRecruit = () => {
    const {
        nameVal,
        numberVal,
        emailVal,
        fileVal,
        agrUse,
        agrCollect
    } = field;

    const regExpNumber =
        /^(\d{0,3})(\d{0,4})(\d{0,4})$/g;
    
    const regExpEmail =
        /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

    let nemberTest = regExpNumber.test(numberVal);
    let emailTest = regExpEmail.test(emailVal);

    if (
      nameVal === '' ||
      numberVal === '' ||
      nemberTest === false ||
      emailVal === '' ||
      emailTest === false ||
      fileVal === null ||
      agrUse === '' ||
      agrCollect === ''
    ) {
      if (nameVal === '') {
        dispatch(openAlert('이름을 입력해주세요.'));
        document.getElementById('1').focus();
      } else if (numberVal === '') {
        dispatch(openAlert('연락처를 입력해주세요.'));
        document.getElementById('2').focus();
      } else if (nemberTest === false) {
        dispatch(openAlert('연락처 형식이 올바르지 않습니다. 확인 후 다시 입력해주세요.'));
        document.getElementById('2').focus();
      } else if (emailVal === '') {
        dispatch(openAlert('이메일을 입력해주세요.'));
        document.getElementById('3').focus();
      } else if (emailTest === false) {
        dispatch(openAlert('이메일 형식이 올바르지 않습니다. 확인 후 다시 입력해주세요.'));
        document.getElementById('3').focus();
      } else if (fileVal === null) {
        dispatch(openAlert('파일을 업로드해주세요.'));
        document.getElementById('file').focus();
      } else if (agrUse === '') {
        dispatch(openAlert('개인정보 수집∙이용 동의를 확인해주세요.'));
        document.getElementById('check1').focus();
      } else if (agrCollect === '') {
        dispatch(openAlert('개인정보 취급위탁에 대한 동의를 확인해주세요.'));
        document.getElementById('check2').focus();
      }
    }
    else {
      recruitData().then(resolvedData => {
        setDisabledSubmit(false);
        let result = resolvedData.type.endsWith('fulfilled');
        
        if (result) {
          setField({
              isSelected: 'C1901',
              nameVal: '',
              numberVal: '',
              emailVal: '',
              urlVal: '',
              fileVal: null,
              textVal: '',
              agrUse: '',
              agrCollect: ''
            });
            alert('입사지원이 완료되었습니다.\n담당자가 확인 후 연락드리겠습니다.');
            window.location.replace('/');
        } else {
            alert('오류가 발생하여 정상적으로 지원되지 않았습니다.');
        }
      });
    }
  };
  
  const onClickUsepopup = () => {
    dispatch(showUse());
  };

  const onClickcollectpopup = () => {
    dispatch(showCollect());
  };
  
  return (
    <BoxUserForm>
      <BoxProjectForm>
        <TextFormTitle>
          <TextEssential>*</TextEssential>
          1. 지원하실 분야를 1가지를 선택해주세요.
        </TextFormTitle>
        <ContactButton
          changed={radioButtonChangeHandler}
          id="1"
          isSelected={field.isSelected === "C1901"}
          label="Front End"
          value="C1901"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="2"
          isSelected={field.isSelected === "C1902"}
          label="Back End"
          value="C1902"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="3"
          isSelected={field.isSelected === "C1903"}
          label="Mobile[Android]"
          value="C1903"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="4"
          isSelected={field.isSelected === "C1904"}
          label="Mobile[iOS]"
          value="C1904"
        />
        <ContactButton
          changed={radioButtonChangeHandler}
          id="5"
          isSelected={field.isSelected === "C1905"}
          label="기타"
          value="C1905"
        />
        <BtnTextJoin title="진행중인 채용공고" href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=SnhWM1pXZHlyQ1VkbU1LUHFqYmo5dz09" target="_blank" >
          진행중인 채용공고 확인
        </BtnTextJoin>
        <BoxTypeCon>
          <TextBoxTitle>자격 요건</TextBoxTitle>
          <TextBoxSub>UI/UX 트렌드와 Mobile Device특성에 대한 이해</TextBoxSub>
          <TextBoxSub>
            기획업무에 필요한 설득력 있는 Paper Work(화면설계서 등 기획업무에
            필요한 각 문서)
          </TextBoxSub>
        </BoxTypeCon>
      </BoxProjectForm>
      <WritingContentForm>
        <TextFormTitle>
          2. 입사지원 파일 첨부와 개인정보를 꼭 입력해주세요.
        </TextFormTitle>
      </WritingContentForm>

      <BoxInputFrom>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>이름</label>
          <input id="1" type="text" placeholder="지원자 이름을 입력해주세요." onChange={onChangeName} />
        </StyledInput>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>연락처</label>
          <input id="2" type="text" value={phoneNum} placeholder="연락 가능한 연락처를 입력해주세요." onChange={onChangeNumber} />
        </StyledInput>
        <StyledInput>
          <InputEssential>*</InputEssential>
          <label>이메일</label>
          <input id="3" type="text" placeholder="이메일 주소를 입력해주세요." onChange={onChangeEmail} />
        </StyledInput>
        <StyledInput>
          <label>URL</label>
          <input id="4" type="text" placeholder="(선택) 참고할 경로를 입력해주세요." onChange={onChangeUrl} />
        </StyledInput>
      </BoxInputFrom>
      <BoxFileBtn className="file_item">
        <TextEssential>*</TextEssential>
        <label htmlFor="file">
          <BtnAddFile>파일 업로드</BtnAddFile>
        </label>
        <input type="file" name="file" id="file" onChange={fileUpload} />
        {
          (window.innerWidth < 769) ? <div style={{margin: `20px 0`}}></div> : null
        }
        <TextFileInfo className="file_info">
          직무관련 및 입사지원 서류를 첨부해 주세요.
          최대 20MB 이하 / 2개이상 파일은 압축하여 zip파일로 올려주세요.
        </TextFileInfo>
      </BoxFileBtn>
      <WritingContentForm>
        <textarea
         name="" 
         id="" 
         cols="30" 
         rows="10" 
         spellCheck="false" 
         placeholder="(선택) 추가하고 싶은 내용이나 문의할 부분이 있으면 적어주세요."
         onChange={onChangeText}
        ></textarea>
      </WritingContentForm>
      <BoxAgree>
        <BoxAgreeItem>
          <input id="check1" type="checkbox" name="agree" onClick={onClickUsepopup} checked={popup.useAgree} required />
          <label htmlFor="check1" style={{'cursor': 'pointer'}}>
            개인정보 수집・이용 동의<span>(필수)</span>
          </label>
        </BoxAgreeItem>
        <BoxAgreeItem>
          <input id="check2" type="checkbox" name="agree" onClick={onClickcollectpopup} checked={popup.collectAgree} required />
          <label htmlFor="check2" style={{'cursor': 'pointer'}}>
            개인정보 취급위탁에 대한 동의<span>(필수)</span>
          </label>
        </BoxAgreeItem>
      </BoxAgree>
      <BoxSubmitBtn>
        <BtnSubmit disabled={disabledSubmit} onClick={onSubmitRecruit}>&lt;&#47;Send&gt;</BtnSubmit>
      </BoxSubmitBtn>
    </BoxUserForm>
  );
}
