import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import requests from '../../api/requests';

const initialState = {
    loading: false,
    poroject: false,
    join: false
};

export const postProject = createAsyncThunk('contact/postProject', async formData => {
    const res = await axios.post(requests.PROJECT, formData, { headers: {"Content-Type": "multipart/form-data"}});
    let projectData = res.data.data;
    return projectData;
});

export const postRecruit = createAsyncThunk('contact/postRecruit', async formData => {
    const res = await axios.post(requests.JOIN, formData, { headers: {"Content-Type": "multipart/form-data"}});
    let recruitData = res.data.data;
    return recruitData;
});

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(postProject.fulfilled, state => {
                state.loading = false;
                state.poroject = true;
            })
            .addCase(postRecruit.fulfilled, state => {
                state.loading = false;
                state.join = true;
            })
    }
});

export default contactSlice;
