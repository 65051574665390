import React from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";

const BoxIndicator = styled.div`
  position: fixed;
  top: 50%;
  right: 54px;
  transform: translate(0, -50%);
  z-index: 100;
  
  @media screen and (max-width:768px) {
    right: 4%;
  }
  `;
  
  const ItemStep = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  background: rgba(31, 31, 31, 0.4);
  transition: all .8s ease-in-out;
  &.active {
    height: 64px;
    background: #db1e26;
  }
  :not(:last-child) {
    margin-bottom: 24px;
  }
  @media screen and (max-width:768px) {
    width: 4px;
    height: 4px;
    &.active {
      height: 32px;
    }
  
  }
`;

export default function Indicator() {

  const indicator = useSelector((state) => state.indicator);

  return (
    <BoxIndicator>
      <ItemStep className={indicator.tabOne ? "active" : null} />
      <ItemStep className={indicator.tabTwo ? "active" : null} />
      <ItemStep className={indicator.tabThree ? "active" : null} />
      <ItemStep className={indicator.tabFour ? "active" : null} />
    </BoxIndicator>
  )
}
