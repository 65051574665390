import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import requests from '../../../api/requests';

const ScrollLock = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
}

const ScrollUnlock = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

const initialState = {
  mode: false,
  noticeDetail: []
};

export const getNoticeDetail = createAsyncThunk('noticePopup/getNoticeDetail', async seq => {
  let seqNum = seq.seq
  if(seqNum !== ''){
    const res = await axios.get(requests.NOTICE_LIST + '/' + seqNum);
    let noticeDetailData = res.data.data;
    return noticeDetailData;
  }
});

export const noticePopupSlice = createSlice({
  name: 'noticePopup',
  initialState,
  reducers: {
    noticePopupShow: (state) => {
      state.mode = true;
      ScrollLock();
    },
    noticePopupHide: (state) => {
      state.mode = false;
      ScrollUnlock();
    },
  },
  extraReducers: builder => {
    builder.addCase(getNoticeDetail.fulfilled, (state, { payload }) => {
        state.noticeDetail = payload.noticeDetail;
    });
  }, 
});

export const { noticePopupShow, noticePopupHide} = noticePopupSlice.actions;

export default noticePopupSlice;
