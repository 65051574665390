import { createSlice } from '@reduxjs/toolkit';

const ScrollLock = () => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
}

const ScrollUnlock = () => {
    const scrollY = document.body.style.top;
    document.body.style.cssText = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

const initialState = {
    alertTitle: '',
    mode: false,
  };
  
export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert: (state, actions) => {
      state.alertTitle = actions.payload;  
      state.mode = true;
      ScrollLock();
    },
    closeAlert: (state) => {
      state.alertTitle = '';
      state.mode = false;
      ScrollUnlock();
    },
  },
});

export const { openAlert, closeAlert } = alertSlice.actions;
export const selectAlert = (state) => state.alert;

export default alertSlice.reducer;