import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabOne: true,
  tabTwo: false,
  tabThree: false,
  tabFour: false,
};

export const indicatorSlice = createSlice({
  name: 'indicator',
  initialState,
  reducers: {
    showOne: (state) => {
        state.tabOne = true;
        state.tabTwo = false;
        state.tabThree = false;
        state.tabFour = false;
    },
    showTwo: (state) => {
        state.tabOne = false;
        state.tabTwo = true;
        state.tabThree = false;
        state.tabFour = false;
    },
    showThree: (state) => {
        state.tabOne = false;
        state.tabTwo = false;
        state.tabThree = true;
        state.tabFour = false;
    },
    showFour: (state) => {
        state.tabOne = false;
        state.tabTwo = false;
        state.tabThree = false;
        state.tabFour = true;
    },
  },
});

export const { showOne, showTwo, showThree, showFour } = indicatorSlice.actions;
export const selectMode = (state) => state.mode;

export default indicatorSlice.reducer;
