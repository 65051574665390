import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TopIcon from "../../../assets/images/ico_top_btn.svg";

const BoxBtnTop = styled.div`
  position: fixed;
  bottom: 116px;
  right: 50px;
  z-index: 100;

  @media screen and (max-width:768px) {
    right: 4%;
    bottom: 12%;
  }
`;

const BtnTop = styled.button`
  width: 64px;
  height: 64px;
  border: 0;
  border-radius: 100%;
  background: rgba(219, 30, 38, 0.8) url("${TopIcon}") no-repeat;
  background-position: center bottom 0;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  
  @media screen and (max-width:768px) {
    width: 32px;
    height: 32px;
    background-size: 50%;
  }
`;

export default function TopButton() {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);
  
  return (
    showButton && (
      <BoxBtnTop>
        <BtnTop id="top" onClick={scrollToTop} type="button" />
      </BoxBtnTop>
    )
  );
}
