import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-scroll";
import styled from 'styled-components';
import Logo from "../../assets/images/brit_logo.png";
import DarkLogo from "../../assets/images/img_logo_header2.svg";

const HeaderWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.9);
  transition: all .3s ease-in-out;
  &.dark {background: rgba(255, 255, 255, 0.1);}
`;

const NavWrap = styled.div`
  /* position: fixed; */
  display: flex;
  max-width: 1700px;
  justify-content: space-between;
  align-items: center;
  /* background-color: #6638f0; */
  margin: 0 auto;
`;

const NavLogo = styled.img`
  display: flex;
  width: 82px;
  height: 36px;
  margin: 22px 60px;
  transition: all .3s ease-in-out;
  /* background-color: #5cc9f5; */
  z-index: 100;
  @media screen and (max-width: 768px){
    margin: 22px 20px;
  }
`;


const MainNav = styled.ul`
  list-style-type: none;
  display: flex;
  cursor: pointer;
  margin-right: 60px;
  

  @media (max-width: 1500px) {
    display: flex !important;
    margin-right: 5px;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 425px){
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    bottom: 2%; left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 60px;
    flex-direction: row;
    justify-content: center;
    transition: all 0.5s;
    background: rgba(0,0,0,0.8);
    color: #fff;
    border-radius: 15px;
  }
  `;

const NavLi = styled.li`
  position: relative;
  text-align: center;
  margin: auto 15px;
  &::after {
    content: '';
    width: 4px;
    height: 4px;
    background: #db1e26;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(17px, -50%);
    transition: all .3s ease-in-out;
    display: none;
  }
  &.dark::after {display:block}
  @media screen and (max-width: 768px){
    margin: 0 5px;
    &::after {
      right: 10px;
    }
  }
  
  @media screen and (max-width: 425px){
    margin: 0;
    padding: 0 15px;
    &::after {
      right: 25px;
    }
  }
  `;
  
  const NavLinkItem = styled.div`
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  transition: all .3s ease-in-out;
  margin: 0px 10px;

  @media screen and (max-width: 768px){
    font-size: 17px;
    margin: 0 5px;
  }
  
  @media (max-width: 425px) {
    text-align: center;
    font-size: 12px;
    height: 100%;
    color: #fff;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.dark{
      color: #fff;
    }
  }
  &.dark {color: #fff;}
  /* background-color: #4af2a1; */
`;

export default function Header() {

  const mode = useSelector((state) => state.header.mode);

  useEffect(()=>{

  },[]);

  
  return (
    <HeaderWrap className={mode && 'dark'}>
      <NavWrap>
        <NavLogo src={mode ? DarkLogo : Logo}/>
        <MainNav id="menu">
          <NavLi className={mode && 'dark'} >
            <NavLinkItem className={mode && 'dark'} >
              <Link to="1" spy={true} smooth={true} style={{'cursor': 'pointer'}}>
                News
              </Link>
            </NavLinkItem>
          </NavLi>
          <NavLi className={mode && 'dark'} >
            <NavLinkItem className={mode && 'dark'} >
              <Link to="2" spy={true} smooth={true} style={{'cursor': 'pointer'}}>
                Technology
              </Link>
            </NavLinkItem>
          </NavLi>
          <NavLi className={mode && 'dark'} >
            <NavLinkItem className={mode && 'dark'} >
              <Link to="3" spy={true} smooth={true} style={{'cursor': 'pointer'}}>
                About
              </Link>
            </NavLinkItem>
          </NavLi>
          <NavLi className={mode && 'dark'}>
            <NavLinkItem className={mode && 'dark'} >
              <Link to="4" spy={true} smooth={true} style={{'cursor': 'pointer'}}>
                Contact
              </Link>
            </NavLinkItem>
          </NavLi>
        </MainNav>
      </NavWrap>
    </HeaderWrap>
  )
}
