import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../../assets/style/noticePopup.css';
import { useAppDispatch } from '../../../store';
import { getNoticeDetail, noticePopupHide } from './noticePopupSlice';

const NoticePopup = (seq) => {
    
    const noticePopup = useSelector((state) => state.noticePopup);
    const noticeDetail = useSelector((state) => state.noticePopup.noticeDetail);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(seq.seq !== ''){
            dispatch(getNoticeDetail(seq));
        }
    }, [seq]);

    const onClickPopupClose = () => {
        dispatch(noticePopupHide());
    };

    return (
        <div className= {noticePopup.mode ? "contain-layer-news active" : "contain-layer-news"}>
            <div className="dim" onClick={onClickPopupClose}/>
            <div className="contain-inner">
                <div className="box_layer_header">
                   <span className="text_item_category">{noticeDetail.noticeGbCdNm}</span>
                   <h1 className="title">
                    {noticeDetail.title}
                   </h1>
                </div>
                <div className="box-privite box-scroll">
                    <p className="text_con_detail" dangerouslySetInnerHTML={{ __html: noticeDetail.contents }} />
                </div>
                <button type="button" className="btn-closelayer" onClick={onClickPopupClose}>닫기</button>
            </div>
        </div>
    );
};

export default NoticePopup;