import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: false
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    darkMode: (state) => {
      state.mode = true;
    },
    lightMode: (state) => {
      state.mode = false;
    }
  },
});

export const { darkMode, lightMode } = headerSlice.actions;
export const selectMode = (state) => state.mode;

export default headerSlice.reducer;
