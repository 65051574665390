import React from "react";
import { Reset } from "styled-reset";
import "./App.css";
import PerCollectPopup from './Components/common/popup/PerCollectPopup';
import PerUsePopup from './Components/common/popup/PerUsePopup';
import Indicator from "./Components/common/view//Indicator";
import TopButton from "./Components/common/view/TopButton";
import Footer from "./Components/footer/Footer";
import Header from "./Components/header/Header";
import Section from "./Components/sections/Section";

function App() {
  return (
    <>
      <Reset />
      <Header />
      <Section />
      <Footer/>
      <Indicator />
      <TopButton />
      <PerCollectPopup />
      <PerUsePopup />
    </>
  );
}

export default App;
