import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TechActive1 from "../../assets/images/img_tech_active1.svg";
import TechActive2 from "../../assets/images/img_tech_active2.svg";
import TechActive3 from "../../assets/images/img_tech_active3.svg";
import TechActive4 from "../../assets/images/img_tech_active4.svg";

const BoxWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 200px;

  @media screen and (max-width:425px){
    padding-top: 120px;
  }
`;

const TextConTitle = styled.strong`
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.02em;
  color: #5f5f5f;
  display: block;
  padding-left: 54px;
  position: absolute;
  top: 150px;
  left: 54px;
  z-index: 500;

  @media screen and (max-width: 768px) {
    left: 27px;
    padding-left: 27px;
  }

  @media screen and (max-width: 425px) {
    font-size: 25px;
    top: 80px;
  }
`;

const BoxContentsService = styled.div`
  padding-left: calc(10vw - 160px);
  padding-top: 50px;
`;

const BoxScrollArea = styled.div`
  width: 100%;
  margin: -50px 0 0 0;
  position: relative;
  z-index: 100;
`;

const BoxServList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  padding: 100px 0 49px;
  margin-left: 54px;
  position: relative;
  z-index: 100;
  // &::-webkit-scrollbar {
  //   height: 2px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   height: 30%;
  //   background: #db1e26;
  // }
  // &::-webkit-scrollbar-track {
  //   background: rgba(271, 271, 271, 0.1);
  // }
`;

const BoxScrollBar = styled.span`
  position: relative;
  display: block;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 80%;
  height: 2px;
  background: rgba(271, 271, 271, 0.1);
  overflow: hidden;
  i {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 25%;
    height: 2px;
    background: #db1e26;
    transform: translateX(0%);
    transition: all 0.3s ease-in-out;
  }
`;

const BoxServItem = styled.li`
  display: inline-block;
  min-width: 570px;
  min-height: 390px;
  margin-right: 84px;
  opacity: 0.7;
  position: relative;
  vertical-align: top;
  z-index: 100;
  &.active {
    opacity: 1;
  }
  &::after {
    content: "";
    display: block;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -100px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
  }
  &.active::after {
    opacity: 1;
  }
  &:nth-child(1)::after {
    background: url("${TechActive1}") no-repeat;
    background-position: top 0 right 0;
    background-size: auto;
  }
  &:nth-child(2)::after {
    background: url("${TechActive2}") no-repeat;
    background-position: top 0 right 0;
    background-size: auto;
  }
  &:nth-child(3)::after {
    background: url("${TechActive3}") no-repeat;
    background-position: top 0 right 0;
    background-size: auto;
  }
  &:nth-child(4)::after {
    background: url("${TechActive4}") no-repeat;
    background-position: top 0 right 0;
    background-size: auto;
  }

  @media screen and (max-width: 768px) {
    min-width: 400px;
    &::after {
      background-size: 40% !important;
      background-position: top 0 right 10% !important;
    }
  }

  @media screen and (max-width: 425px) {
    min-width: 300px;
    min-height: 350px;
  }
`;

const TextServTop = styled.p`
  font-weight: 900;
  font-size: 42px;
  line-height: 61px;
  letter-spacing: -0.02em;
  color: #160e0e;
  text-shadow: -1px 0px #fff, 0px 1px #fff, 1px 0px #fff, 0px -1px #fff;
  &.active {
    text-shadow: none;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 50px;
  }

  @media screen and (max-width: 425px) {
    font-size: 20px;
    line-height: 40px;
  }
`;

const TextServSub = styled.span`
  margin-top: 66px;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  white-space: normal;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

const BoxTag = styled.span`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #fff;
  span {
    color: #db1e26;
  }
`;

const TechnologyScrollBox = () => {
  const [scrollItem, setScrollItem] = useState({
    scrollItem1: true,
    scrollItem2: false,
    scrollItem3: false,
    scrollItem4: false,
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(Draggable);

    const boxWrap = document.getElementById("boxWrap");
    const view = document.getElementById("scrollView");
    const bar = document.getElementById("scrollBar");
    const wi = window.innerWidth

    const animationSec2 = gsap.timeline({
      scrollTrigger: {
        trigger: boxWrap,
        pin: true,
        start: "top top",
        end: `bottom `,
        scrub: 1,
      },
      onComplete: () => {
        Draggable.create(view, {
          type: "x",
          onDrag: scrollItem,
        });
      },
    });

    if (wi > 768) {
      animationSec2
        .to(view, {
          duration: 1.2,
          x: -500 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 100 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -540 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 200 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -1500 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 300 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -1900 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: true,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: true,
            });
          },
        });
    }else if ( (wi <= 768 ) && (wi > 425)){
      animationSec2
        .to(view, {
          duration: 1.2,
          x: -400 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 100 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -430 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 200 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -950 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 300 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -1400 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: true,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: true,
            });
          },
        });
    }else if ( (wi <= 425 )) {
      animationSec2
        .to(view, {
          duration: 1.2,
          x: -380 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x:  100 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -380 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: true,
              scrollItem2: true,
              scrollItem3: false,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 200 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -785 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: true,
              scrollItem3: true,
              scrollItem4: false,
            });
          },
        })
        .to(bar, {
          duration: 0,
          x: 300 + "%",
        })
        .to(view, {
          duration: 1.2,
          x: -1150 + "px",
          onStart() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: false,
              scrollItem4: true,
            });
          },
          onReverseComplete() {
            setScrollItem({
              ...scrollItem,
              scrollItem1: false,
              scrollItem2: false,
              scrollItem3: true,
              scrollItem4: true,
            });
          },
        });
    }
  }, []);

  return (
    <>
      <BoxWrap id="boxWrap">
        <TextConTitle>&lt;&#47;Technology&gt;</TextConTitle>
        <BoxContentsService id="scrollView">
          <BoxScrollArea>
            <BoxServList>
              <BoxServItem className={scrollItem.scrollItem1 ? "active" : null}>
                <TextServTop>
                  VR 관리 솔루션
                  <br />
                  VR, AR, 메타버스
                </TextServTop>
                <TextServSub>
                  VR을 통한 온라인 전시 공간을 통해 시공간 제약 없는 온라인 전시
                  개최, 체험형 전시 컨텐츠 개발, 전시 성과 측정을 도와주는 VR
                  관리 솔루션을 제공해 드립니다.
                </TextServSub>
                <BoxTag>
                  <span>#</span>VR, <span>#</span>AR, <span>#</span>메타버스,
                  <span>#</span>VR 관리 솔루션
                </BoxTag>
              </BoxServItem>
              <BoxServItem className={scrollItem.scrollItem2 ? "active" : null}>
                <TextServTop>
                  라이브 커머스
                  <br />
                  구축 및 컨설팅
                </TextServTop>
                <TextServSub>
                  모바일로 보는 실시간 예능형 쇼핑을 통해 온/오프라인 매장의
                  장점을 결합한 새로운 쇼핑 형태로 대기업 뿐만 아니라 중소기업,
                  신규브랜드, 소상공인, 개인 등 누구나 판매의 주체가 될 수 있는
                  라이브 커머스 시스템 구축을 제안해 드립니다.
                </TextServSub>
                <BoxTag>
                  <span>#</span>라이브 커머스 <span>#</span>라이브 스트리밍 기반
                  특허 출원
                </BoxTag>
              </BoxServItem>
              <BoxServItem className={scrollItem.scrollItem3 ? "active" : null}>
                <TextServTop>
                  토탈 시스템
                  <br />
                  구축 및 컨설팅
                </TextServTop>
                <TextServSub>
                  고객사의 시스템 운용 관리현황을 분석, 파악하여 문제점의 도출
                  및 향후 개선점을 제공하고, 전략적 신기술 적용을 위한 기술 동향
                  소개 및 투자에 따르는 비용 효과를 효율적으로 산출하고
                  전산시스템의 운영 및 관리 체제의 중앙 집중 일원화 및 자동화를
                  목적으로 기업의 생산성 향상과 글로벌 경쟁력 확보에 도움을
                  드리고 있습니다.
                </TextServSub>
                <BoxTag>
                  <span>#</span>VR, <span>#</span>AR, <span>#</span>메타버스,
                  <span>#</span>VR 관리 솔루션
                </BoxTag>
              </BoxServItem>
              <BoxServItem className={scrollItem.scrollItem4 ? "active" : null}>
                <TextServTop>
                  가상화폐 거래소
                  <br />
                  구축 및 컨설팅
                </TextServTop>
                <TextServSub>
                  국내 최초의 트레이딩 마이닝(POT) 개념을 도입한 암호화폐 시스템
                  구축 및 운영 경험을 기반으로 코인상장을 할 수 있도록 필요한
                  지갑개발 및 사이트 구축을 제안해 드립니다. 전 세계 거래량 기준
                  상위권에 랭크된 경험을 기반으로 안정적인 거래 및 서비스 품질을
                  보장해 드립니다.
                </TextServSub>
                <BoxTag>
                  <span>#</span>트레이딩마이닝, <span>#</span>ISO 27001 인증,
                  <span>#</span>아마존 클라우드 시스템
                </BoxTag>
              </BoxServItem>
            </BoxServList>
          </BoxScrollArea>
        </BoxContentsService>
        <BoxScrollBar>
          <i id="scrollBar" />
        </BoxScrollBar>
      </BoxWrap>
    </>
  );
};

export default TechnologyScrollBox;
