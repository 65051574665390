import styled, { css } from "styled-components";

function ContactButton({ id, changed, isSelected, label, value }) {
  return (
    <StyledButton
      id={id}
      onClick={changed}
      value={value}
      type="radio"
      checked={isSelected}
    >
      <label htmlFor={id}>{label}</label>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  display: inline-block;
  margin-right: 4px;
  margin-top: 13px;
  outline: none;
  border: none;
  border-radius: 4px;
  color: #3f3f3f;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  cursor: pointer;
  padding: 7px 17px;
  label {
    cursor: pointer;
  };
  ${({ checked }) =>
    checked
      ? css`
          background: #fff1f1;
          border: 1px solid #db1e26;
          color: #db1e26;
          font-weight: 700;
        `
      : null};
`;

export default ContactButton;
