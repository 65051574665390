import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from "styled-components";
import { useAppDispatch } from '../../store';
import { showTwo } from '../common/view/indicatorSlice';
import { darkMode } from '../header/headerSlice';
import TechnologyScrollBox from './TechnologyScrollBox';

const TechnologyWrap = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  height: auto;
  background: linear-gradient(147.21deg, #160e0e 35.96%, #230505 100%);
  overflow: hidden;
  position: relative;
`;

const BoxContentView = styled.div`
  width: 300%;
  display: flex; 
`;

const TextAni = keyframes`
  0% {
    left: 100%;
  }
  100% {
    left: -5923.83PX;
  }
`;

const BoxContentsText = styled.p`
  position: relative;
  width: auto;
  padding: 28px 0 186px;
  font-size: 180px;
  font-weight: 900;
  line-height: 261px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.1);
  white-space: nowrap;
  animation: ${TextAni} 20s linear infinite;
  
  @media screen and (max-width:768px){
    font-size: 90px;
    line-height: 130.5px;
    padding-bottom: 93px;
  }
`;

const BoxContentsSkill = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 54px;
  height: auto;
  padding-bottom: 233px;
  
  @media screen and (max-width:768px){
    padding: 0 27px 126.5px 27px;
  }
`;

const TechTextConTitle = styled.strong`
  text-align: center;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.02em;
  color: #5f5f5f;
  display: block;

  @media screen and (max-width:768px){
    font-size: 5vw;
    line-height: 5.5vw;
  }
`;

const SkillAni = keyframes`
  0% {
    color: #5e5e5e;
  }
  30% {
    color: #db1e26;
  }
  100% {
    color: #db1e26;
  }
`;

const TextSkill = styled.p`
  padding: 72px 54px 0;
  font-weight: 900;
  font-size: 64px;
  line-height: 96px;
  text-align: center;
  letter-spacing: -0.02em;
  .skil_item {
    color: #5e5e5e;
    &.active {
      animation: ${SkillAni} 3s linear;
    }
  }

  @media screen and (max-width:768px){
    font-size: 5vw;
    line-height: 7.5vw;
    padding: 36px 25px 0;
  }
  `;

export default function Technology() {

  const dispatch = useAppDispatch();

  const [ref, inView] = useInView();

  useEffect(()=>{
    if(inView) {
      dispatch(darkMode());
      dispatch(showTwo());
    }
  },[inView]);

  const skilArr = [
    'Java, ',
    'JavaScript, ',
    'Spring, ',
    'HTML/CSS, ',
    'jQuery, ',
    'JSP, ',
    'React, ',
    'Vue.js, ',
    'Angular, ',
    'Oracle, ',
    'MySQL, ',
    'Spring Boot, ',
    'PHP, ',
    'Python, ',
    'Node.js, ',
    'Swift, ',
    'E-Government Framework, ',
    'Kotlin, ',
    'Flutter, ',
    'MSSQL, ',
    '... Etc. '
  ]
  
  const [randomVal, setRandomVal] = useState({
    Num1: null, 
    Num2: null, 
    Num3: null 
  });
  
  let randomNenber1 = Math.floor(Math.random() * (19 - 0) + 1)
  let randomNenber2 = Math.floor(Math.random() * (19 - 0) + 1)
  let randomNenber3 = Math.floor(Math.random() * (19 - 0) + 1)

  useEffect(()=>{
    setTimeout(() => {
      setRandomVal({...randomVal, Num1:randomNenber1, Num2:randomNenber2, Num3:randomNenber3})
    }, 3000)
    
  },[randomVal]);


 

  return (
    <TechnologyWrap>

      <TechnologyScrollBox/>
      
      <BoxContentView id="contentsView">
        <BoxContentsText className="contentsText" ref={ref}>
          VR, AR, 메타버스, VR 관리 솔루션, 영상+미디어+라이브커머스 스트리밍 플랫폼 구축
        </BoxContentsText>
      </BoxContentView>

      <BoxContentsSkill>
        <TechTextConTitle>&lt;&#47;All-in-one Technology&gt;</TechTextConTitle>
        <TextSkill>
        {skilArr.map((item, i) => (
          <i key={i} className={i === randomVal.Num1 || i === randomVal.Num2 || i === randomVal.Num3 ? "skil_item active" : "skil_item"}>{item}</i>
        ))}
        </TextSkill>
      </BoxContentsSkill>
    </TechnologyWrap>
  );
}
