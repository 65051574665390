import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import requests from '../../api/requests';

const initialState = {
    list: []
};

export const getNotice = createAsyncThunk('notice/getNotice', async code => {
    if(code === null) {
        const res = await axios.get(requests.NOTICE_LIST);
        let noticesData = res.data.data;
        return noticesData;
    } else {
        const res = await axios.get(requests.NOTICE_LIST + "?noticeGbCd=" + code);
        let noticesData = res.data.data;
        return noticesData;
    }
    
});

const noticeSlice = createSlice({
    name: 'notice',
    initialState,
    extraReducers: builder => {
        builder.addCase(getNotice.fulfilled, (state, { payload }) => {
            state.list = payload.list;
        });
    },
});

export default noticeSlice;
