import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from "styled-components";
import AboutImg1 from "../../assets/images/img_about_con1.svg";
import AboutImg2 from "../../assets/images/img_about_con2.svg";
import AboutImg3 from "../../assets/images/img_about_con3.svg";
import AboutImg4 from "../../assets/images/img_about_con4.svg";
import { useAppDispatch } from '../../store';
import { showThree } from '../common/view/indicatorSlice';
import { lightMode } from '../header/headerSlice';

const AboutWrap = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 54px;
  height: auto;
  background: #f5f5f5;
  overflow: hidden;

  @media screen and (max-width:1160px){
    padding: 0 2.5%;
  }
`;

const BoxContentAbout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px 0;
  padding: 101px 60px 149px;

  @media screen and (max-width: 1160px){
    padding: 100px 0;
  }
`;

const TextConTitle = styled.strong`
  width: 100%;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  display: block;
`;

const BoxSubTitle = styled.div`
  margin-top: 40px;
  width: 100%;
  span {
    font-size: 60px;
    font-weight: 900;
    line-height: 87px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #1f1f1f;
    &:last-child {
      color: #db1e26;
    }
  }

  @media screen and (max-width: 1160px){
    margin-top: 20px;
    span{
      font-size: 40px;
      line-height: 60px;
    }
  }
`;

const BoxBenefitCard = styled.ul`
  position: relative;
  height: 440px;
  width: 1160px;
  margin: 42px auto;
  li {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    position: absolute;
    white-space: nowrap;
  }
  
  @media screen and (max-width:1160px){
    margin-top: 42px;
    width: 90%;
    margin: 42px auto;
    
  }
  @media screen and (max-width:550px){
    margin: 42px auto;
    height: 1330px;
  }
`;

const IconUpAni = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
`;

const IconDownAni = keyframes`
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const IconSideAni = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
`;

const IconLineAni = keyframes`
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-15px, -15px);
  }
`;

const BoxCard2 = styled.li`
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
  padding: 32px 76px 32px 32px;
  background: #db1e26;
  top: 0;
  left: 1600px;
  span {
    font-weight: 900;
  }
  &::after {
    content: "";
    display: block;
    width: 112px;
    height: 112px;
    background: url("${AboutImg1}") no-repeat;
    background-size: cover;
    position: absolute;
    top: -61px;
    right: 0;
    animation: ${IconUpAni} 0.7s forwards linear infinite alternate;
  }
  
  @media screen and (max-width:1160px){
    top:-40px;
    padding: 15px;
    font-size: 20px;
    &::after {
      top: -40px;
      width: 60px;
      height: 60px;
    }
  }
  
  @media screen and (max-width:550px){
    box-sizing: border-box;
    top: 0px;
    left: 700px;
    padding: 32px;
    padding-top: 42px;
    width: 100%;
    &::after {
      top: -61px;
      width: 112px;
      height: 112px;
    }
  }
`;

const BoxCard1 = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  padding: 16px 43px 12px;
  background: #fff;
  left: 1624px;
  bottom: 0;
  
  @media screen and (max-width:1160px){
    font-size: 15px;
    padding: 14px 20px;
    left: 1600px;
  }
  
  @media screen and (max-width:550px){
    font-size: 18px;
    box-sizing: border-box;
    top: 190px;
    left: 700px;
    width: 100%; height: 78px;
    line-height: 50px;
  }
`;

const BoxCard3 = styled.li`
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #fff;
  background: #3f3f3f;
  padding: 14px 19px;
  bottom: 384px;
  left: 1994px;

  @media screen and (max-width:1160px){
    padding: 15px;
    font-size: 15px;
    left: 1860px
  }
  
  @media screen and (max-width:550px){
    box-sizing: border-box;
    top: 280px; left: 700px;
    width: 100%; height: 100px;
    padding: 20px;
    text-align: center;
    font-size: 20px;
  }
`;

const BoxCard4 = styled.li`
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  background: #1f1f1f;
  padding: 32px 62px 32px 32px;
  color: #fff;
  bottom: 0;
  left: 1878px;
  p {
    font-size: 28px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 32px;
    color: #fff;
  }
  span {
    font-weight: 700;
  }
  &::after {
    content: "";
    display: block;
    width: 97px;
    height: 97px;
    background: url("${AboutImg2}") no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -35px;
    right: 16px;
    z-index: 1;
    animation: ${IconSideAni} 1s linear infinite alternate;
  }

  @media screen and (max-width:1160px){
    left: 1599px;
    bottom: 170px;
    padding: 15px;
    font-size: 15px;
    &::after {
      width: 60px;
      height: 60px;
    }

    p{
      font-size: 15px;
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width:550px){
    font-size: 16px;
    line-height: 30px;
    box-sizing: border-box;
    top: 400px; left: 700px;
    width: 100%; height: 250px;
    padding: 20px;
    text-align: center;
    
    p{
      margin-top: 50px;
      font-size: 28px;
      text-align: center;
    }

    &::after {
      bottom: -20px;
    }
  }
`;

const BoxCard5 = styled.li`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  padding: 29px 40px;
  color: #1f1f1f;
  background: #fff;
  text-align: center;
  left: 2148px;
  bottom: 253px;
  span {
    font-weight: 700;
  }

  @media screen and (max-width:1160px){
    left: 1599px;
    bottom: 70px;
    padding: 15px;
    font-size: 15px;
  }

  @media screen and (max-width:550px){
    font-size: 15px;
    box-sizing: border-box;
    top: 680px; left: 700px;
    width: 100%; height: 90px;
    padding: 20px;
    text-align: center;
  }
`;

const BoxCard6 = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  background: #fff;
  padding: 19px 45px 13px;
  right: -1336px;
  bottom: 0;
  span {
    font-weight: 700;
  }

  @media screen and (max-width:1160px){
    left: 1800px;
    padding: 15px;
    font-size: 15px;
  }

  @media screen and (max-width:550px){
    font-size: 15px;
    box-sizing: border-box;
    top: 790px; left: 700px;
    width: 100%; height: 90px;
    padding: 20px;
    text-align: center;
  }
`;

const BoxCard7 = styled.li`
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #fff;
  background: #db1e26;
  padding: 14px 19px;
  right: -1326px;
  bottom: 125px;
  &::after {
    content: "";
    display: block;
    width: 82px;
    height: 82px;
    background: url("${AboutImg3}") no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 72px;
    right: 5px;
    animation: ${IconDownAni} .6s forwards ease-out infinite alternate;
  }

  @media screen and (max-width:550px){
    font-size: 15px;
    box-sizing: border-box;
    top: 900px; left: 700px;
    width: 100%; height: 90px;
    padding: 20px;
    text-align: center;
    &::after {
      bottom: 0px;
    }
  }
`;

const BoxCard8 = styled.li`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f1f1f;
  background: #fff;
  padding: 45px 55px 125px;
  right: -1600px;
  bottom: 0;
  &::after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    background: url("${AboutImg4}") no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -45px;
    right: 43px;
    animation: ${IconLineAni} 1.5s forwards linear infinite alternate;
  }
  span {
    color: #db1e26;
  }

  @media screen and (max-width:550px){
    box-sizing: border-box;
    top: 1010px; left: 700px;
    width: 100%; height: 400px;
    padding: 20px;
    
  }
`;

export default function About() {

  useEffect(() => { 
    gsap.registerPlugin(ScrollTrigger);

    const aboutWrap = document.getElementById('aboutWrap');
    const boxCard = document.querySelectorAll('.box_card');
    const wi = window.innerWidth

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: aboutWrap,
        pin: true,
        start: 'top top',
        end: 'bottom bottom',
        scrub: 1,
      },
    });

    if ( wi > 550 ){
      for (let i = 0; i < boxCard.length; i++) {
        timeline.to(boxCard[i], {
          duration: 1.2 + i * 0.2,
          opacity: 1,
          x: -1600,
        });
      }
    }else{
      for (let i = 0; i < boxCard.length; i++) {
        timeline.to(boxCard[i], {
          duration: 1,
          opacity: 1,
          x: -700,
        });
      }
    }


  }, []);


  const dispatch = useAppDispatch();

  const [ref, inView] = useInView();

  useEffect(()=>{
    if(inView) {
      dispatch(lightMode());
      dispatch(showThree());
    }
  },[inView]);
  
  return (
    <AboutWrap id="aboutWrap" ref={ref}>
      <BoxContentAbout>
        <TextConTitle>&lt;&#47;We are BRIT&gt;</TextConTitle>
        <BoxSubTitle>
          <span>비알아이티</span>
          <span>
            에선
            <br />
            모두가{" "}
          </span>
          <span>프로</span>
        </BoxSubTitle>
        <BoxBenefitCard id="aboutInner" ref={ref}>
          <BoxCard2 className="box_card">
            <span>열정</span>적인 당신에게 맞는 <br />
            연봉과 상/하반기
            <br />
            특별 상여를 지급합니다.
          </BoxCard2>
          <BoxCard1 className="box_card">필요시 재택 근무 전환</BoxCard1>
          <BoxCard3 className="box_card">
            자유로운
            <br />
            연차휴가
          </BoxCard3>
          <BoxCard4 className="box_card">
            <div>
            <span>커피</span>와 <span>간식</span> 무한 제공 <br />
            도서 및 온라인 강의, 자격증 취득비 지원
            <br />
            개인 노트북 지원, 모니터 제공
            <p>아낌없이 지원합니다.</p>
            </div>
          </BoxCard4>
          <BoxCard5 className="box_card">
            둘째, 넷째주 <br />
            <span>리프레쉬데이 4시 퇴근</span>
          </BoxCard5>
          <BoxCard6 className="box_card">
            점심은 소중하니까
            <br />
            <span>1시간 30분</span> 점심시간
          </BoxCard6>
          <BoxCard7 className="box_card">
            우수사원
            <br />
            포상제도
          </BoxCard7>
          <BoxCard8 className="box_card">
            자유로운 <br className="br_tag" />
            분위기에서 <br />
            능력자들과
            <br className="br_tag" />
            <span>함께</span> 일하고
            <br />
            <span>같이</span> 성장 <br className="br_tag" />
            가능합니다.
          </BoxCard8>
        </BoxBenefitCard>
      </BoxContentAbout>
    </AboutWrap>
  );
}
