import React from 'react';
import { useSelector } from 'react-redux';
import '../../../assets/style/popup.css';
import { useAppDispatch } from '../../../store';
import { agreeCollect, disagreeCollect, popupHide } from './popupSlice';

const PerCollectPopup = () => {
  const popup = useSelector((state) => state.popup);

  const dispatch = useAppDispatch();

  const onClickPopupClose = () => {
    dispatch(popupHide());
  };

  const onClickDisagree = () => {
    dispatch(disagreeCollect());
  };
  
  const onClickAgree = () => {

    let PerCollect = {};
    
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let hour = d.getHours();
    let minute = d.getMinutes();
    let second = d.getSeconds();
    let dateData = `${(year)}-${('0' + month).slice(-2)}-${('0' + date).slice(-2,)}(${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}:${('0' + second).slice(-2)})`;

    PerCollect =
      document.getElementById('PerCollectContent').innerHTML +
      '동의 일시' +
      '\u00A0' +
      '|' +
      '\u00A0' +
      dateData;

    dispatch(agreeCollect(PerCollect));
  };

  return (
    <>
      {popup.collectMode && 
        <div className="contain-layer">
          <div className="dim" onClick={onClickPopupClose}/>
          <div className="contain-inner">

              <h1 className="title">개인정보 취급위탁에 대한 동의</h1>
              <p className="text-information1">회사는 수집된 개인정보를 이용자의 동의가 있는 경우에 한하여 아래와 같이 제3자에 제공하고 있습니다.</p>

              <table className="table-type1" id='PerCollectContent'>
                  <title>개인정보 취급위탁 목적, 제공받는 자의 명칭 제공되는 개인정보, 제공기간</title>
                  <colgroup>
                  <col width="168" />
                  </colgroup>
                  <tr>
                      <th>제공 목적</th>
                      <td>시스템 운영</td>
                  </tr>
                  <tr>
                      <th>제공받는 자의 명칭</th>
                      <td>비알아이티㈜</td>
                  </tr>
                  <tr>
                      <th>제공되는 개인정보</th>
                      <td>
                          <ul className="list-type1">
                              <li>프로젝트 의뢰 : 성명, 이메일, 연락처, 회사명</li>
                              <li>입사지원 : 성명, 이메일, 연락처</li>
                          </ul>
                      </td>
                  </tr>
                  <tr>
                      <th>제공 기간</th>
                      <td><strong>접수 후 1년간 보유</strong></td>
                  </tr>
              </table>

              <p className="text-information2">정보주체는 개인정보 수집에 대한 동의를 거부할 권리가 있습니다.<br />단, 미동의 시 문의 및 제안 서비스를 이용하실 수 없습니다.</p>

              <div className="box-buttons">
                  <button type="button" className="btn-type1" onClick={onClickDisagree} style={{'cursor': 'pointer'}}>취소</button>
                  <button type="button" className="btn-type2" onClick={onClickAgree} style={{'cursor': 'pointer'}}>동의</button>
              </div>

          </div>
        </div>
      }
    </>
  );
};

export default PerCollectPopup;
